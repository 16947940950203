<template>
  <div class="footer_content_box">
    <div class="footer_content">
      <div class="top">
        <div class="left">
          <img src="/static/footer/logo.png" />
          <div class="desc">
            <div>客服部座机：400-6622-773</div>
            <div>公司地址：重庆市渝北区天宫殿街道橡树壹号三楼</div>
            <!-- <div>
              分公司地址：成都市青羊区太升南路53号附57号沃特国际通讯大厦2109
            </div> -->
          </div>
        </div>
        <div class="center">
          <div class="title">快速链接</div>
          <div class="list">
            <div v-for="(k, i) of list" :key="i" @click="toUrl(k)">
              {{ k.name }}
            </div>
          </div>
        </div>
        <div class="right">
          <img src="/static/footer/qrcode.png" />
          <div>爱尚往约官方公众号</div>
        </div>
      </div>
      <div class="bottom">
        <div>
          公司名称：重庆汇中堂健康管理有限公司 <br>
           <a target="_blank"  class="link" href="https://beian.miit.gov.cn">备案号：渝ICP备20005474号</a>
        </div>
        <div>
          <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002503239" class="link">
          <img src="/static/icon/gongan.png" alt=""> 渝公网安备 50019002503239号
         </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { reactive } from "vue";
const router = useRouter();
const list = reactive([
  {
    name: "关于我们",
    url: "/about",
    pageName: "about",
  },
  {
    name: "产品服务",
    url: "/product",
    pageName: "product",
  },
  {
    name: "城市加盟",
    url: "/join",
    pageName: "join",
  },
  {
    name: "往约事件",
    url: "/news",
    pageName: "news",
  },
  {
    name: "商家入驻",
    url: "/settlein",
    pageName: "settlein",
  },
  {
    name: "联系我们",
    url: "/contact",
    pageName: "contact",
  },
  {
    name: "企业介绍",
    url: "/company",
    pageName: "company",
  },
]);

const toUrl = (data) => {
  router.push(data.url);
  window.scrollTo(0, 0)
};
</script>

<style lang="scss" scoped>
.link{
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #fff;
}
.bottom {
  font-size: 14px;
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
}

.footer_content_box {
  background: #42BBB4;
  padding: 40px 0px;
}

.footer_content {
  box-sizing: border-box;
  background: #42BBB4;
  margin: 0 auto;
  padding: 147px 90px 0 90px;

  .top {
    border-bottom: 1px solid #fff;
    .left {
      //   width: 630px;
      & > img {
        width: 329px;
      }
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #ffffff;
    }
    .center {
      box-sizing: border-box;
      .title {
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #ffffff;
      }
      .list {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        & > div {
          width: 30%;
          text-align: left;
          font-family: Source Han Sans CN;
          font-weight: 300;
          color: #ffffff;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 187px;
      text-align: center;
      & > img {
        width: 100%;
      }
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #ffffff;
    }
  }
}

@media screen and (min-width: 0px) {
  .footer_content {
    padding: 20px;
  }
  .top {
    display: block;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    .left {
      width: 100%;
      margin: 0 auto;
      & > img {
        width: 329px;
        margin-bottom: 20px;
      }
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    .center {
      padding: 0px;
      box-sizing: border-box;
      border: none;
      .title {
        font-size: 23px;
        margin-bottom: 10px;
      }
      .list {
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        & > div {
          text-align: left;
          font-size: 12px;
          line-height: 30px;
          cursor: pointer;
          text-align: center;
        }
      }
      margin-bottom: 30px;
    }
    .right {
      width: 187px;
      margin: 0 auto;
      text-align: center;
      & > img {
        width: 100%;
      }
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #ffffff;
      line-height: 37px;
    }
  }
  .bottom {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
    & img{
      width: 12px;
    }
  }
}
@media screen and (min-width: 768px) {
  .footer_content {
    padding: 20px;
  }
  .top {
    display: block;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    .left {
      width: 100%;
      margin: 0 auto;
      & > img {
        width: 329px;
        margin-bottom: 20px;
      }
      font-size: 15px;
      line-height: 30px;
      margin-bottom: 30px;
    }
    .center {
      padding: 0px;
      box-sizing: border-box;
      border: none;
      .title {
        font-size: 23px;
        margin-bottom: 10px;
      }
      .list {
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        & > div {
          text-align: left;
          font-size: 12px;
          line-height: 30px;
          cursor: pointer;
          text-align: center;
        }
      }
      margin-bottom: 30px;
    }
    .right {
      width: 187px;
      margin: 0 auto;
      text-align: center;
      & > img {
        width: 100%;
      }
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 300;
      color: #ffffff;
      line-height: 37px;
    }
  }
  .bottom {
    margin-top: 20px;
    font-size: 12px;
    line-height: 20px;
  }
}
@media screen and (min-width: 998px) {
  .bottom {
    margin-top: 55px;
    font-size: 20px;
    line-height: 40px;
    & img{
      width: 20px;
    }
  }
  .footer_content {
    padding: 147px 90px 0 90px;
  }
  .top {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #fff;
    padding-bottom: 100px;
    .left {
      width: 630px;
      & > img {
        width: 329px;
        margin-bottom: 40px;
      }
      font-size: 18px;
      line-height: 40px;
    }
    .center {
      width: 540px;
      border-left: 1px solid #FFF;
      border-right: 1px solid #FFF;
      padding: 0 87px 0px 85px;
      .title {
        font-size: 23px;
        margin-bottom: 50px;
      }
      .list {
        display: flex;
        width: 100%;
        justify-content: space-between;
        flex-wrap: wrap;
        & > div {
          width: 110px;
          text-align: left;
          font-size: 20px;
          line-height: 45px;
          cursor: pointer;
        }
      }
    }

    .right {
      width: 187px;
      text-align: center;
      & > img {
        width: 100%;
      }
      font-size: 16px;
      line-height: 37px;
    }
  }
}
</style>
