<template>
  <div class="nav_box">
    <div class="nav_content">
      <img src="/static/logo.png" alt="" class="img" />
      <div
        class="itme big_show"
        :class="pageName == k.pageName ? 'act' : ''"
        v-for="k of list"
        :key="k.pageName"
        @click="handleClick(k)"
      >
        {{ k.name }}
      </div>
      <div class="mini_show btn">
        <el-icon @click="showBox = true"><Fold /></el-icon>
      </div>
      <div class="mini_show">
        <el-drawer v-model="showBox" :with-header="false" size="200px">
          <el-icon @click="showBox = false" size="20"><Expand /></el-icon>
          <div style="margin-top: 20px">
            <div
              class="itme"
              :class="pageName == k.pageName ? 'act' : ''"
              v-for="k of list"
              :key="k.pageName"
              @click="handleClick(k)"
            >
              {{ k.name }}
            </div>
          </div>
        </el-drawer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from "vue-router";
import { defineProps, ref, reactive } from "vue";
const props = defineProps({
  pageName: String,
});
const { pageName } = props;
const router = useRouter();
const list = reactive([
  {
    name: "首页",
    url: "/",
    pageName: "home",
  },
  {
    name: "关于我们",
    url: "/about",
    pageName: "about",
  },
  {
    name: "产品服务",
    url: "/product",
    pageName: "product",
  },
  {
    name: "城市加盟",
    url: "/join",
    pageName: "join",
  },
  {
    name: "往约事件",
    url: "/news",
    pageName: "news",
  },
  {
    name: "商家入驻",
    url: "/settlein",
    pageName: "settlein",
  },
  {
    name: "联系我们",
    url: "/contact",
    pageName: "contact",
  },
  {
    name: "企业介绍",
    url: "/company",
    pageName: "company",
  },
]);
const showBox = ref(false);

const handleClick = (k) => {
  router.push(k.url);
};
</script>

<style lang="scss" scoped>
.nav_content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  background-color: #fff !important;
  z-index: 999;
  .img {
    height: 50%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .itme {
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #606060;
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #42BBB4;
    }
  }
  .btn {
    font-size: 20px;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
  .act {
    color: #42BBB4 !important;
  }
}
@media screen and (min-width: 0px) {
  .nav_box {
    height: 60px;
  }
  .nav_content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    justify-content: center;
    .img {
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    .itme {
      width: 150px;
      line-height: 40px;
      font-size: 16px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
@media screen and (min-width: 768px) {
  .nav_content {
    height: 60px;
    justify-content: center;
    .img {
      left: 50%;
      transform: translateY(-50%) translateX(-50%);
    }
    .itme {
      width: 150px;
      line-height: 40px;
      font-size: 16px;
      border-bottom: 1px solid #f0f0f0;
    }
  }
}
@media screen and (min-width: 998px) {
  .nav_box {
    height: 137px;
  }
  .nav_content {
    position:relative;
    height: 137px;
    justify-content: flex-end;
   
    .img {
      left: 0;
      transform: translateY(-50%);
    }
    .itme {
      display: block;
      width: 120px;
      font-size: 20px;
      border: none;
    }
  }
}
</style>
